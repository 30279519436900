import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import UserMenu from './UserMenu';
import { Box, IconButton, Menu, MenuItem, useMediaQuery, useTheme, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo-rec.png';

const listaMenu = [
  { text: 'Eventos', route: 'eventos', icon: <EventOutlinedIcon fontSize="small" /> },
  { text: 'Validar Presença', route: 'validar-presenca', icon: <CheckCircleOutlineIcon fontSize="small" /> },
  { text: 'Leads', route: 'leads', icon: <PeopleOutlineIcon fontSize="small" /> },
  { text: 'Relatórios', route: 'relatorios', icon: <BarChartOutlinedIcon fontSize="small" /> },
];

export default function TopMenu() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoClick = () => {
    navigate('/home');
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: '#ff3c3e',
        color: '#ffffff',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      }}
    >
      <Toolbar sx={{ minHeight: '64px' }}>
        <Box
          component="img"
          src={logo}
          alt='logo'
          onClick={handleLogoClick}
          sx={{
            height: '40px',
            marginRight: '20px',
            transition: 'transform 0.2s',
            cursor: 'pointer',
            '&:hover': {
              transform: 'scale(1.05)'
            }
          }}
        />
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
              sx={{ ml: 'auto' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  mt: 1.5,
                  borderRadius: 2,
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                }
              }}
            >
              {listaMenu.map((rota) => (
                <MenuItem
                  key={rota.text}
                  component={Link}
                  to={`/${rota.route}`}
                  onClick={handleMenuClose}
                  sx={{
                    py: 1.5,
                    px: 2,
                    color: '#333333',
                    '&:hover': {
                      backgroundColor: '#fff5f5',
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    {rota.icon}
                    {rota.text}
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            gap: 1,
            ml: 2
          }}>
            {listaMenu.map((rota) => {
              const isActive = location.pathname === `/${rota.route}`;
              return (
                <Tooltip key={rota.text} title={rota.text} arrow>
                  <Typography
                    variant="body1"
                    component={Link}
                    to={`/${rota.route}`}
                    sx={{
                      color: isActive ? '#ffffff' : 'rgba(255, 255, 255, 0.8)',
                      textDecoration: 'none',
                      borderRadius: '8px',
                      padding: '8px 16px',
                      transition: 'all 0.2s',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      '&:hover': {
                        backgroundColor: isActive ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.05)',
                        transform: 'translateY(-1px)',
                        color: '#ffffff',
                      },
                    }}
                  >
                    {rota.icon}
                    {rota.text}
                  </Typography>
                </Tooltip>
              );
            })}
          </Box>
        )}
        <Box sx={{ ml: 2 }}>
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
}