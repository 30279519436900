// eslint-disable-next-line import/named
import { Button, ButtonProps } from "@mui/material"
import React from 'react'
import { styled } from '@mui/material/styles'

interface DefaultButtonProps extends ButtonProps {
  children: React.ReactNode;
}

const DefaultButton = styled(Button)(() => ({
  backgroundColor: '#ff3c3e',
  color: 'white',
  '&:hover': {
    backgroundColor: '#e63537',
  },
  '&.Mui-disabled': {
    backgroundColor: '#ff9a9c',
  },
  '&.MuiButton-outlined': {
    backgroundColor: 'transparent',
    color: '#ff3c3e',
    borderColor: '#ff3c3e',
    '&:hover': {
      backgroundColor: '#fff5f5',
      borderColor: '#e63537',
    },
    '&.Mui-disabled': {
      color: '#ff9a9c',
      borderColor: '#ff9a9c',
    }
  }
}))

const DefaultButtonComponent: React.FC<DefaultButtonProps> = ({ children, ...props }) => {
  return (
    <DefaultButton
      {...props}
      sx={{
        ...props.sx,
      }}
    >
      {children}
    </DefaultButton>
  )
}

export const EditButton: React.FC<DefaultButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        backgroundColor: '#ffffff',
        color: '#ff3c3e',
        border: '1px solid #ff3c3e',
        '&:hover': {
          backgroundColor: '#fff5f5',
          borderColor: '#e63537',
        },
        '&:disabled': {
          backgroundColor: '#ffffff',
          color: '#ff9a9c',
          borderColor: '#ff9a9c',
        },
        ...props.sx,
      }}
    >
      {children}
    </Button>
  )
}

export default DefaultButtonComponent