import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { TextField, Typography, Grid, Box } from '@mui/material';
import DefaultButton from '../components/inputs/buttons/DefaultButton';
import axios from 'axios';
import eventBg from '../assets/event-bg.jpg';
import logo from '../assets/logo-rec.png';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 &&
      (error.response.data.message === 'Token expired' ||
        error.response.data.message === 'No token provided' ||
        error.response.data.message === 'Invalid token')) {
      if (window.location.pathname !== '/login') {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('isAuthenticated');
        // window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await AuthService.login(username, password);
      if (response.data.success) {
        const { accessToken } = response.data;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('isAuthenticated', 'true');
        window.dispatchEvent(new Event('authChange')); // Dispara o evento personalizado
        navigate('/home');
      } else {
        setError(response.data.message || 'Login failed. Please check your username and password.');
      }
    } catch (error) {
      setError('Usuário ou senha incorretos.');
      console.error('Login failed', error);
    }
  };

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item xs={12} md={6} sx={{ position: 'relative', display: { xs: 'none', md: 'block' } }}>
        <Box
          component="img"
          src={eventBg}
          alt="Event Background"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: 0.7,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ width: '80%', maxWidth: '400px', textAlign: 'center' }}>
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{
              width: { xs: '250px', md: '350px' },
              marginBottom: '20px',
            }}
          />
          <Typography variant="h4" align="center" gutterBottom>
            Login
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <DefaultButton type='submit' variant="contained" fullWidth sx={{ mt: 3, mb: 2 }}>
              Login
            </DefaultButton>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;