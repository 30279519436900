import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Snackbar, ButtonGroup, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AuthService from '../../services/AuthService';
import FormUsers from '../forms/FormUsers';
import TableCellWithTooltip from '../inputs/TableCellWithTooltip';
import UserService from '../../services/UserService';

interface User {
  id: string;
  name: string;
  username: string;
  email: string;
  role: string;
}

interface TabelaUsuariosProps {
  buscarTudo: () => void;
}

const TabelaUsuarios: React.FC<TabelaUsuariosProps> = ({ buscarTudo }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await UserService.getUsers();
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setOpenSnackbar(true);
    }
  };

  const handleDelete = async (userId: string) => {
    try {
      await AuthService.deleteUser(userId);
      fetchUsers();
      buscarTudo();
    } catch (error) {
      setOpenSnackbar(true);
      console.error('Erro ao deletar usuário:', error);
    }
  };

  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setOpenEditForm(true);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table aria-label="tabela de usuários">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Nome</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Usuário</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Email</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Cargo</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id} hover>
                <TableCellWithTooltip content={user.name} maxLength={30} />
                <TableCellWithTooltip content={user.username} maxLength={20} />
                <TableCellWithTooltip content={user.email} maxLength={40} />
                <TableCell align="center">
                  {user.role === 'admin' ? 'Administrador' :
                    user.role === 'operator' ? 'Operador' : 'Usuário'}
                </TableCell>
                <TableCell align="center">
                  <ButtonGroup
                    disableElevation
                    variant="outlined"
                    aria-label="Disabled button group"
                    sx={{
                      '& .MuiButtonGroup-grouped': {
                        borderColor: '#ff3c3e',
                        '&:hover': {
                          borderColor: '#e63537',
                        }
                      }
                    }}
                  >
                    <IconButton
                      onClick={() => handleEdit(user)}
                      sx={{
                        color: '#ff3c3e',
                        '&:hover': {
                          backgroundColor: '#fff5f5',
                        }
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(user.id)}
                      sx={{
                        color: '#ff3c3e',
                        '&:hover': {
                          backgroundColor: '#fff5f5',
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FormUsers
        open={openEditForm}
        setOpen={setOpenEditForm}
        user={selectedUser}
        onSuccess={() => {
          fetchUsers();
          buscarTudo();
        }}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Erro ao deletar usuário"
      />
    </>
  );
};

export default TabelaUsuarios; 