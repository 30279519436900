import axios from 'axios';
import { baseURL } from '../App';

class UserService {
  async getUsers() {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error('No token found in localStorage');
      throw new Error('No token found');
    }

    console.log('Making request to:', `${baseURL}/users`);
    console.log('With token:', token);

    try {
      const response = await axios.get(`${baseURL}/users`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Users response:', response);
      return response;
    } catch (error) {
      console.error('Error in getUsers:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        console.error('Response headers:', error.response?.headers);
        console.error('Request headers:', error.config?.headers);
      }
      throw error;
    }
  }
}

export default new UserService(); 