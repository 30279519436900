import { Dialog, DialogTitle, DialogContent, Card, CardContent, Grid, Box, Typography, CardActions, DialogActions, Button } from "@mui/material"
import { QRCodeSVG } from "qrcode.react"
import FormConvidado from "../forms/FormConvidado"
import DefaultButton from "../inputs/buttons/DefaultButton"
import { useReactToPrint } from 'react-to-print';
import { useState, useRef, memo, useMemo } from "react";
import SubscriberService from "../../services/SubscriberService";

const SubscriberBox = memo(({ subscriber, open, setOpen }: { subscriber: any, open: boolean, setOpen: (value: boolean) => void }) => {
  const [openFormConvidado, setOpenFormConvidado] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const contentToPrint = useRef(null);

  const getFontSizeClass = (name: string) => {
    if (name.length > 20) return "smallest";
    if (name.length > 15) return "smaller";
    if (name.length > 10) return "small";
    return "";
  };

  const fontSizeClass = useMemo(() => {
    return subscriber && subscriber.name ? getFontSizeClass(subscriber.name) : "";
  }, [subscriber]);

  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    removeAfterPrint: true,
  });

  const handlePrintClick = () => {
    if (!subscriber.credenciado) {
      setOpenConfirmation(true);
    } else {
      handlePrint(null, () => contentToPrint.current);
    }
  };

  const handleConfirmPrint = () => {
    subscriber.credenciado = true;
    subscriber.confirmation_timestamp = [new Date().getTime().toString()];
    SubscriberService.updateSubscriber(subscriber.id, {
      ...subscriber,
      event: subscriber.event.id,
      ticket: subscriber.ticket.id,
    });
    setOpenConfirmation(false);
    handlePrint(null, () => contentToPrint.current);
  };

  function getDateAndTime(timestamp: string) {
    if (!timestamp) return new Date().toLocaleString();
    const date = new Date(Number(timestamp));
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title" sx={
        { display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '900px' }
      }>
        <DialogTitle id="form-dialog-title">Participante do evento</DialogTitle>
        <DialogContent>
          {subscriber && (
            <>
              <Card sx={{ padding: 2 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <Box className="boxParticipante">
                        <div className='p28'>
                          <Typography variant="h6"><strong>Nome:</strong> {subscriber.name}</Typography>
                          <Typography variant="h6"><strong>E-mail:</strong> {subscriber.email}</Typography>
                          <Typography variant="h6"><strong>Documento:</strong> {subscriber.document}</Typography>
                          <Typography variant="h6"><strong>Telefone:</strong> {subscriber.phone}</Typography>
                          <Typography variant="h6"><strong>Código da transação:</strong> {subscriber.transaction_code}</Typography>
                          <Typography variant="h6"><strong>Credenciado:</strong> {subscriber.credenciado ? 'Sim' : 'Não'}</Typography>
                          <Typography variant="h6"><strong>Tipo de ingresso:</strong> {subscriber.ticket.name} {subscriber.ticket.guests}</Typography>
                          {subscriber.event.hasReward && <Typography variant="h6"><strong>Premiação:</strong> {subscriber.reward ? subscriber.reward : 'N/A'}</Typography>}
                          <Typography variant="h6"><strong>Observações:</strong> {subscriber.notes ? subscriber.notes : 'N/A'}</Typography>
                        </div>
                      </Box>
                      {
                        Number(subscriber.ticket.guests) > 0 && Number(subscriber.guests) < Number(subscriber.ticket.guests) && !subscriber.owner ?
                          <CardActions className='justify-center mt30'>
                            <DefaultButton
                              size="small"
                              type="submit"
                              variant="contained"
                              onClick={() => setOpenFormConvidado(true)}
                            >Cadastrar convidado</DefaultButton>
                          </CardActions>
                          : null
                      }
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center" sx={{ flexWrap: "wrap" }}>
                      <div ref={contentToPrint} className='qrcode-sub'>
                        <QRCodeSVG
                          value={subscriber.id}
                          size={70}
                          bgColor="#FFFFFF"
                          fgColor="#000000"
                          level="Q"
                          style={{ margin: 'auto', float: 'right', padding: '4px 4px 0px 0px' }}
                        />

                        <p
                          style={{
                            fontSize: "10px",
                            textAlign: "start",
                            paddingLeft: "18px",
                            fontWeight: "bold",
                          }}
                        >{getDateAndTime(subscriber?.confirmation_timestamp ? subscriber?.confirmation_timestamp[0] : null)}</p>
                        <div className={`cracha dynamic-font-size ${fontSizeClass} ${subscriber.name.length > 12 ? 'break-word' : ''}`}>{subscriber.name}</div>
                        <p
                          style={{
                            fontSize: "10px",
                            textAlign: "start",
                            paddingLeft: "18px",
                            fontWeight: "bold",
                            textAlignLast: "right",
                            marginTop: "-5px",
                          }}
                        >{subscriber.ticket.name} {subscriber.ticket.guests}</p>
                      </div>
                      <CardActions className='mt30'>
                        <DefaultButton
                          size="small"
                          type="submit"
                          variant="contained"
                          onClick={handlePrintClick}
                        >Imprimir Etiqueta</DefaultButton>
                      </CardActions>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          )}
          {subscriber &&
            <FormConvidado
              open={openFormConvidado}
              setOpen={setOpenFormConvidado}
              subscriber={subscriber}
            />
          }
        </DialogContent>
      </Dialog>

      <Dialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">Confirmação</DialogTitle>
        <DialogContent>
          <Typography>O participante não está credenciado. Deseja credenciá-lo e imprimir a etiqueta?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmation(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmPrint} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})

SubscriberBox.displayName = 'SubscriberBox'
export default SubscriberBox