import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Snackbar, ButtonGroup } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EventService from '../../services/EventService';
import FormEventos from '../forms/FormEventos';
import { usePermissions } from '../../hooks/usePermissions';
import TableCellWithTooltip from '../inputs/TableCellWithTooltip';

interface Event {
  id: string;
  eventName: string;
  description: string;
  tags: string[] | null;
}

interface TabelaEventosProps {
  eventos: Event[];
  buscarTudo: () => void;
}

const TabelaEventos: React.FC<TabelaEventosProps> = ({ eventos, buscarTudo }) => {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const { isAdmin, isOperator } = usePermissions();

  const handleRowClick = (eventId: string) => {
    navigate(`/evento/${eventId}`);
  };

  const handleDelete = useCallback(async (eventId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      await EventService.deleteEvent(eventId);
      buscarTudo();
    } catch {
      setOpenSnackbar(true);
    }
  }, [buscarTudo]);

  const handleEdit = (event: Event, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedEvent(event);
    setOpenEditForm(true);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table aria-label="tabela de eventos">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Nome do Evento</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Descrição</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Tags</TableCell>
              {(isAdmin || isOperator) && (
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Ações</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {eventos.map((evento) => (
              <TableRow key={evento.id} hover onClick={() => handleRowClick(evento.id)} sx={{ cursor: 'pointer' }}>
                <TableCellWithTooltip content={evento.eventName} maxLength={30} />
                <TableCellWithTooltip content={evento.description} maxLength={50} />
                <TableCellWithTooltip content={evento.tags?.join(', ')} maxLength={40} />
                {(isAdmin || isOperator) && (
                  <TableCell align='center'>
                    <ButtonGroup
                      disableElevation
                      variant="outlined"
                      aria-label="Disabled button group"
                      sx={{
                        '& .MuiButtonGroup-grouped': {
                          borderColor: '#ff3c3e',
                          '&:hover': {
                            borderColor: '#e63537',
                          }
                        }
                      }}
                    >
                      <IconButton
                        onClick={(e) => handleEdit(evento, e)}
                        sx={{
                          color: '#ff3c3e',
                          '&:hover': {
                            backgroundColor: '#fff5f5',
                          }
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      {isAdmin && (
                        <IconButton
                          onClick={(e) => handleDelete(evento.id, e)}
                          sx={{
                            color: '#ff3c3e',
                            '&:hover': {
                              backgroundColor: '#fff5f5',
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ButtonGroup>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FormEventos
        open={openEditForm}
        setOpen={setOpenEditForm}
        buscarTudo={buscarTudo}
        event={selectedEvent}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Erro ao deletar evento"
      />
    </>
  );
};

export default TabelaEventos;