import React, { useEffect, useState } from 'react';
import TabelaEventos from '../components/tabelas/TabelaEventos';
import FormEventos from '../components/forms/FormEventos';
import DefaultButton from '../components/inputs/buttons/DefaultButton';
import { useEventos } from '../hooks/useEventos';
import { usePermissions } from '../hooks/usePermissions';
import PageHeader from '../components/PageHeader';

function Eventos() {
  const { events, buscarTudo } = useEventos();
  const [open, setOpen] = useState(false);
  const { isAdmin, isOperator } = usePermissions();

  useEffect(() => {
    document.title = 'Eventos';
  }, []);

  return (
    <>
      <PageHeader
        title="Eventos"
        subtitle="Gerencie todos os eventos do sistema"
      >
        {(isAdmin || isOperator) && (
          <DefaultButton variant='outlined' onClick={() => setOpen(true)}>
            Adicionar evento
          </DefaultButton>
        )}
      </PageHeader>
      <FormEventos open={open} setOpen={setOpen} buscarTudo={buscarTudo} />
      <TabelaEventos eventos={events} buscarTudo={buscarTudo} />
    </>
  );
}

export default Eventos;