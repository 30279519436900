/* eslint-disable prefer-const */
import { useState, useEffect } from "react";
import EventService from "../services/EventService";
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, MenuItem, Select } from "@mui/material";
import DefaultButton from "../components/inputs/buttons/DefaultButton";
import SubscriberService from "../services/SubscriberService";
import { CSVLink } from "react-csv";
import PageHeader from "../components/PageHeader";

export default function Leads() {

  const [selectedEvent, setSelectedEvent] = useState('');
  const [events, setEvents] = useState([]);
  const [id, setId] = useState(false);
  const [name, setName] = useState(false);
  const [email, setEmail] = useState(false);
  const [documento, setDocumento] = useState(false);
  const [phone, setPhone] = useState(false)
  const [ticket, setTicket] = useState(false)
  const [transaction_code, setTransactionCode] = useState(false)
  const [credenciado, setCredenciado] = useState(false)
  const [guests, setGuests] = useState(false)
  const [reward, setReward] = useState(false)
  const [notes, setNotes] = useState(false)
  const [subscribers, setSubscribers] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fezCheckin, setFezCheckin] = useState(false);
  const [nomeEvento, setNomeEvento] = useState('');

  useEffect(() => {
    document.title = 'Leads';
    buscarTudo()
  }, []);

  function buscarTudo() {
    EventService.getEvents().then((response) => {
      setEvents(response.data);
    }
    );
  }
  async function exportarLeads() {
    setLoading(true)
    await SubscriberService.getSubscribersByEventId(selectedEvent).then(async (response) => {
      setSubscribers(response.data)
      // response.data = fezCheckin ? response.data.filter((subscriber: any) => subscriber.confirmation_timestamp.length > 0) : response.data;
      if (fezCheckin) {
        response.data = response.data.filter((subscriber: any) =>
          Array.isArray(subscriber.confirmation_timestamp) &&
          subscriber.confirmation_timestamp.length > 0 &&
          subscriber.confirmation_timestamp.every((timestamp: any) => timestamp !== null)
        );
      }
      const subscriberByField = response.data.map((subscriber: any) => {
        let subsFields: any = {}
        if (id) {
          subsFields.id = subscriber.id
        }
        if (name) {
          subsFields.name = subscriber.name
        }
        if (email) {
          subsFields.email = subscriber.email
        }
        if (documento) {
          subsFields.document = subscriber.document
        }
        if (phone) {
          subsFields.phone = subscriber.phone
        }
        if (ticket) {
          subsFields.ticket = subscriber.ticket.name
        }
        if (transaction_code) {
          subsFields.transaction_code = subscriber.transaction_code
        }
        if (credenciado) {
          subsFields.credenciado = subscriber.credenciado
        }
        if (guests) {
          subsFields.guests = subscriber.guests || 0
        }
        if (reward) {
          subsFields.reward = subscriber.reward || 0
        }
        if (notes) {
          subsFields.notes = subscriber.notes || ''
        }
        console.log("SUBS", subsFields)
        return subsFields
      })

      console.log("EXPORT", subscriberByField)
      setCsvData(subscriberByField);
      setLoading(false)

      // export csv com subscriberByField

    })
    console.log(subscribers)
    // separar todos os campos selecionados pelos checkboxes e exportar
  }

  function handleEventChange(event: any) {
    setSelectedEvent(event.target.value);
    const eventSelected: any = events.find((evento: any) => evento.id === event.target.value)
    console.log("eventSelected", eventSelected)
    if (eventSelected) {
      setNomeEvento(eventSelected.eventName);
    }
  }
  console.log("selectedEvent", selectedEvent)
  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PageHeader
              title="Leads"
              subtitle="Exporte dados dos participantes dos eventos"
            />
          </Grid>
          <Grid item xs={12}>
            <h2>Selecione o evento:</h2>
            <Select
              fullWidth
              label={"Eventos"}
              placeholder="EVENTOS"
              value={selectedEvent}
              onChange={handleEventChange}
            >
              {events.map((event: any) => (
                <MenuItem key={event.id} value={event.id}>{event.eventName}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <h2>Escolha os campos para exportar:</h2>
            <FormGroup row>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel
                    control={<Checkbox value={fezCheckin} onChange={(e) => setFezCheckin(e.target.checked)} />}
                    label="FEZ CHECKIN"
                    className="fez-checkin-checkbox"
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel control={<Checkbox value={id} onChange={(e) => setId(e.target.checked)} />} label="ID" />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel control={<Checkbox value={name} onChange={(e) => setName(e.target.checked)} />} label="Nome" />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel control={<Checkbox value={email} onChange={(e) => setEmail(e.target.checked)} />} label="E-mail" />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel control={<Checkbox value={documento} onChange={(e) => setDocumento(e.target.checked)} />} label="Documento" />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel control={<Checkbox value={phone} onChange={(e) => setPhone(e.target.checked)} />} label="Telefone" />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel control={<Checkbox value={ticket} onChange={(e) => setTicket(e.target.checked)} />} label="Ticket" />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel control={<Checkbox value={transaction_code} onChange={(e) => setTransactionCode(e.target.checked)} />} label="Código da transação" />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel control={<Checkbox value={credenciado} onChange={(e) => setCredenciado(e.target.checked)} />} label="Credenciado" />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel control={<Checkbox value={guests} onChange={(e) => setGuests(e.target.checked)} />} label="Convidados (Quantidade)" />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel control={<Checkbox value={reward} onChange={(e) => setReward(e.target.checked)} />} label="Premiação" />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel control={<Checkbox value={notes} onChange={(e) => setNotes(e.target.checked)} />} label="Observações" />
                </Grid>
              </Grid>
            </FormGroup>
            <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
              <DefaultButton type="submit" onClick={exportarLeads}>Gerar Lista</DefaultButton>
              {csvData.length > 0 && (
                <CSVLink data={csvData} filename={`${new Date().toISOString().slice(0, 16).replace('T', ' ')} - ${nomeEvento}.csv`} style={{ marginLeft: '10px' }}>
                  <DefaultButton style={{ marginLeft: '10px' }}>Baixar lista de leads</DefaultButton>
                </CSVLink>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div >
  );
}