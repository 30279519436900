import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Box, Typography, Tooltip } from '@mui/material';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import GroupIcon from '@mui/icons-material/Group';

const drawerWidth = 280;

const menuItems = [
  { text: 'Eventos', route: 'eventos', icon: <EventOutlinedIcon /> },
  { text: 'Validar Presença', route: 'validar-presenca', icon: <CheckCircleOutlineIcon /> },
  { text: 'Usuários', route: 'usuarios', icon: <GroupIcon /> },
  { text: 'Leads', route: 'leads', icon: <PeopleOutlineIcon /> },
  { text: 'Relatórios', route: 'relatorios', icon: <BarChartOutlinedIcon /> },
];

export default function SideMenu() {
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#1a237e',
          color: 'white',
        },
      }}
    >
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        }}
      >
        <img
          src='/checkpoint-logo.png'
          alt='logo'
          style={{
            height: '65px',
            objectFit: 'cover',
            marginBottom: '16px',
          }}
        />
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          Sistema de Credenciamento
        </Typography>
      </Box>
      <List sx={{ mt: 2 }}>
        {menuItems.map((item) => {
          const isActive = location.pathname === `/${item.route}`;
          return (
            <ListItem key={item.text} disablePadding>
              <Tooltip title={item.text} placement="right" arrow>
                <ListItemButton
                  component={Link}
                  to={`/${item.route}`}
                  sx={{
                    minHeight: 48,
                    px: 2.5,
                    '&:hover': {
                      backgroundColor: isActive ? 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 255, 255, 0.08)',
                    },
                    backgroundColor: isActive ? 'rgba(255, 255, 255, 0.15)' : 'transparent',
                  }}
                >
                  <Box
                    sx={{
                      minWidth: 0,
                      mr: 3,
                      display: 'flex',
                      alignItems: 'center',
                      color: isActive ? '#fff' : 'rgba(255, 255, 255, 0.7)',
                    }}
                  >
                    {item.icon}
                  </Box>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      color: isActive ? '#fff' : 'rgba(255, 255, 255, 0.7)',
                      '& .MuiTypography-root': {
                        fontWeight: isActive ? 500 : 400,
                      },
                    }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}