import React, { useState } from 'react';
import FormUsers from '../components/forms/FormUsers';
import PageHeader from '../components/PageHeader';
import { Container, Grid } from '@mui/material';
import { usePermissions } from '../hooks/usePermissions';
import TabelaUsuarios from '../components/tabelas/TabelaUsuarios';
import DefaultButton from '../components/inputs/buttons/DefaultButton';

function Usuarios() {
  const [openForm, setOpenForm] = useState(false);
  const { isAdmin } = usePermissions();

  const handleFormSuccess = () => {
    setOpenForm(false);
  };

  return (
    <Container maxWidth="lg" style={{ padding: '20px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            title="Usuários"
            subtitle="Gerencie os usuários do sistema"
          >
            {isAdmin && (
              <DefaultButton variant="outlined" onClick={() => setOpenForm(true)}>
                Adicionar usuário
              </DefaultButton>
            )}
          </PageHeader>
        </Grid>
        <Grid item xs={12}>
          <FormUsers
            open={openForm}
            setOpen={setOpenForm}
            onSuccess={handleFormSuccess}
          />
        </Grid>
        {isAdmin && (
          <Grid item xs={12}>
            <TabelaUsuarios buscarTudo={handleFormSuccess} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default Usuarios;