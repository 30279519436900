import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Snackbar, ButtonGroup } from '@mui/material';
import TicketService from '../../services/TicketService';
import React, { useState } from 'react';
import DefaultButton, { EditButton } from '../inputs/buttons/DefaultButton';
import FormTickets from '../forms/FormTickets';
import { usePermissions } from '../../hooks/usePermissions';
import TableCellWithTooltip from '../inputs/TableCellWithTooltip';

const TabelaTickets: React.FC<{ tickets: any[], buscarTudo: () => void, eventId: string }> = ({ tickets, buscarTudo, eventId }) => {
  const [open, setOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const { isAdmin, isOperator } = usePermissions();

  function getGuests(ticket: any) {
    switch (ticket.guests) {
      case "0":
        return 'Single'
      case "1":
        return 'Double'
      case "2":
        return 'Triple'
      default:
        return ``;
    }
  }

  async function deleteTicket(ticketId: string, event: any) {
    event.stopPropagation()
    await TicketService.deleteTicket(ticketId)
    buscarTudo()
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
        <Table aria-label="tabela de tickets" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Nome</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Tipo</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align='center'>Quantidade de Convidados</TableCell>
              {(isAdmin || isOperator) && (
                <TableCell sx={{ fontWeight: "bold" }} align='center'>Ações</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.map((ticket: any) => (
              <TableRow key={ticket.id} hover>
                <TableCellWithTooltip content={ticket.name} maxLength={30} />
                <TableCellWithTooltip content={getGuests(ticket)} maxLength={10} />
                <TableCell align='center'>{ticket.guests}</TableCell>
                {(isAdmin || isOperator) && (
                  <TableCell align='center'>
                    <ButtonGroup
                      disableElevation
                      variant="outlined"
                      aria-label="Disabled button group"
                      sx={{
                        '& .MuiButtonGroup-grouped': {
                          borderColor: '#ff3c3e',
                          '&:hover': {
                            borderColor: '#e63537',
                          }
                        }
                      }}
                    >
                      <EditButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedTicket(ticket);
                          setOpen(true);
                        }}
                      >Editar</EditButton>
                      {isAdmin && (
                        <DefaultButton
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteTicket(ticket.id, e);
                          }}
                        >Excluir</DefaultButton>
                      )}
                    </ButtonGroup>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FormTickets
        open={open}
        setOpen={setOpen}
        buscarTudo={buscarTudo}
        ticket={selectedTicket}
        eventId={eventId}
      />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ backgroundColor: 'red', transition: 'all 0.5s ease-in-out' }}
        onClick={() => setOpen(false)}
        message={`Você não pode deletar esse ticket!`}
      />
    </>
  );
};

export default TabelaTickets;