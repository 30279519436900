import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import TabelaTickets from "../tabelas/TabelaTickets";
import FormTickets from "../forms/FormTickets";
import DefaultButton from "../inputs/buttons/DefaultButton";
import { useState } from "react";


export default function TicketsBox({ tickets, open, setOpen, buscarTudo, eventId }: { tickets: any, open: boolean, setOpen: (value: boolean) => void, buscarTudo: () => void, eventId: string }) {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title" sx={
        { display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '900px' }
      }>
        <DialogTitle id="form-dialog-title">Ingressos</DialogTitle>
        <DialogContent>
          <div className='flex-container'>
            <div style={{
              marginBottom: '10px'
            }}>
              <h3>Tabela de Ingressos</h3>
              <TabelaTickets tickets={tickets} buscarTudo={buscarTudo} eventId={eventId} />
              <DefaultButton onClick={() => setOpenForm(true)}>Adicionar Ticket</DefaultButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <FormTickets open={openForm} setOpen={setOpenForm} buscarTudo={buscarTudo} eventId={eventId} />
    </>
  );
}