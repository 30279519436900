import { TableCell, Tooltip } from '@mui/material';
import React from 'react';

interface TableCellWithTooltipProps {
  content: string | number | null | undefined;
  maxLength?: number;
  align?: 'left' | 'center' | 'right';
  children?: React.ReactNode;
}

const TableCellWithTooltip: React.FC<TableCellWithTooltipProps> = ({
  content,
  maxLength = 50,
  align = 'center',
  children
}) => {
  const text = content?.toString() || '';
  const truncatedText = text.length > maxLength
    ? `${text.substring(0, maxLength)}...`
    : text;

  return (
    <TableCell align={align}>
      <Tooltip
        title={text.length > maxLength ? text : ''}
        arrow
        placement="top"
      >
        <span>{children || truncatedText}</span>
      </Tooltip>
    </TableCell>
  );
};

export default TableCellWithTooltip; 