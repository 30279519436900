import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Grid, CardActionArea } from '@mui/material';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import GroupIcon from '@mui/icons-material/Group';

const menuItems = [
  {
    title: 'Eventos',
    description: 'Gerencie seus eventos e ingressos',
    icon: <EventOutlinedIcon sx={{ fontSize: 40 }} />,
    route: '/eventos',
    color: '#ff3c3e'
  },
  {
    title: 'Validar Presença',
    description: 'Confirme a presença dos participantes',
    icon: <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />,
    route: '/validar-presenca',
    color: '#4caf50'
  },
  {
    title: 'Usuários',
    description: 'Gerencie usuários do sistema',
    icon: <GroupIcon sx={{ fontSize: 40 }} />,
    route: '/usuarios',
    color: '#2196f3'
  },
  {
    title: 'Leads',
    description: 'Visualize e gerencie leads',
    icon: <PeopleOutlineIcon sx={{ fontSize: 40 }} />,
    route: '/leads',
    color: '#ff9800'
  },
  {
    title: 'Relatórios',
    description: 'Acesse relatórios e estatísticas',
    icon: <BarChartOutlinedIcon sx={{ fontSize: 40 }} />,
    route: '/relatorios',
    color: '#9c27b0'
  }
];

function Home() {
  const navigate = useNavigate();

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4, color: '#333' }}>
        Bem-vindo ao Sistema de Credenciamento
      </Typography>
      <Grid container spacing={3}>
        {menuItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.title}>
            <Card
              sx={{
                height: '100%',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 3
                }
              }}
            >
              <CardActionArea onClick={() => navigate(item.route)} sx={{ height: '100%' }}>
                <CardContent sx={{ textAlign: 'center', p: 3 }}>
                  <Box sx={{ color: item.color, mb: 2 }}>
                    {item.icon}
                  </Box>
                  <Typography variant="h6" component="h2" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Home; 