import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import { AuthProvider } from './contexts/AuthContext';
import Evento from './pages/Evento';
import useAuthStatus from './hooks/useAuthStatus';
import ConfirmarPresenca from './pages/ConfirmarPresenca';
import Eventos from './pages/Eventos';
import Ticket from './pages/Ticket';
import Inscrito from './pages/Inscrito';
import Leads from './pages/Leads';
import Usuarios from './pages/Usuarios';
import Relatorios from './pages/Relatorios';
import DefaultLayout from './DefaultLayout';
import Credenciamento from './pages/offpages/Credenciamento';
import Home from './pages/Home';

// export const baseURL = 'http://localhost:5002';
export const baseURL = 'https://afabrica-siscred-bk.onrender.com';

function App() {
  const { isAuthenticated, loading } = useAuthStatus();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={isAuthenticated ? <Navigate replace to="/home" /> : <Login />} />
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="/credenciamento" element={<Credenciamento />} />
          <Route element={<DefaultLayout />}>
            <Route path="/home" element={isAuthenticated ? <Home /> : <Navigate replace to="/login" />} />
            <Route path="/eventos" element={isAuthenticated ? <Eventos /> : <Navigate replace to="/login" />} />
            <Route path="/evento/:id" element={isAuthenticated ? <Evento /> : <Navigate replace to="/login" />} />
            <Route path="/ticket/:id" element={isAuthenticated ? <Ticket /> : <Navigate replace to="/login" />} />
            <Route path="/subscriber/:id" element={isAuthenticated ? <Inscrito /> : <Navigate replace to="/login" />} />
            <Route path="/validar-presenca" element={isAuthenticated ? <ConfirmarPresenca /> : <Navigate replace to="/login" />} />
            <Route path="/leads" element={isAuthenticated ? <Leads /> : <Navigate replace to="/login" />} />
            <Route path="/usuarios" element={isAuthenticated ? <Usuarios /> : <Navigate replace to="/login" />} />
            <Route path="/relatorios" element={isAuthenticated ? <Relatorios /> : <Navigate replace to="/login" />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;