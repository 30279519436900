import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  role: string;
  id: string;
  name: string;
}

export const usePermissions = () => {
  const [userRole, setUserRole] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOperator, setIsOperator] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      try {
        const decoded = jwtDecode<DecodedToken>(token);
        setUserRole(decoded.role);
        setIsAdmin(decoded.role === 'admin');
        setIsOperator(decoded.role === 'operator');
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, []);

  return {
    userRole,
    isAdmin,
    isOperator,
    canManage: (role: string) => role === 'admin' || role === 'operator',
  };
}; 