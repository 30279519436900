import { useEffect, useState } from "react";
import EventService from "../../services/EventService";
import UserService from "../../services/UserService";
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, Snackbar, FormControlLabel, Checkbox, MenuItem, Select, FormControl, InputLabel, Alert } from "@mui/material";
import dayjs from 'dayjs';
import DefaultButton from "../inputs/buttons/DefaultButton";
import { usePermissions } from "../../hooks/usePermissions";

export default function FormEventos({ open, setOpen, buscarTudo, event = null }: { open: boolean, setOpen: (value: boolean) => void, buscarTudo: () => void, event?: any }) {
  const { isAdmin, isOperator } = usePermissions();
  const [eventName, setEventName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [duration, setDuration] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [hasReward, setHasReward] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [users, setUsers] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!open) return; // Só busca usuários quando o modal estiver aberto

      setLoading(true);
      try {
        console.log('Fetching users...');
        const response = await UserService.getUsers();
        console.log('Users response:', response.data);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setMessage('Erro ao carregar lista de usuários');
        setSeverity('error');
        setOpenSnackBar(true);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [open]); // Adiciona open como dependência

  useEffect(() => {
    if (event) {
      setEventName(event.eventName);
      setDescription(event.description);
      setStartDate(event.startDate);
      setDuration(event.duration);
      setHasReward(event.hasReward);
      setSelectedOwner(event.owner?.id || '');
    }
  }, [event])

  const handleSubmit = (eventoForm: { preventDefault: () => void; }) => {
    eventoForm.preventDefault();
    if (!eventName || !startDate || !duration || !selectedOwner) {
      setOpenSnackBar(true);
      setMessage('Por favor, preencha todos os campos obrigatórios.');
      setSeverity('error');
      return;
    }
    const newEvent = {
      eventName: eventName,
      description: description,
      startDate: startDate,
      hasReward: hasReward,
      duration: duration,
      ownerId: selectedOwner
    };
    if (event) {
      EventService.updateEvent(event.id, newEvent).then(() => {
        setOpenSnackBar(true)
        setMessage('Evento atualizado com sucesso')
        setSeverity('success')
        buscarTudo()
      }).catch(() => {
        setOpenSnackBar(true)
        setMessage('Erro ao atualizar evento')
        setSeverity('error')
      })
    } else {
      EventService.createEvent(newEvent).then(() => {
        setOpenSnackBar(true)
        setMessage('Evento criado com sucesso')
        setSeverity('success')
        buscarTudo()
      }).catch(() => {
        setOpenSnackBar(true)
        setMessage('Erro ao criar evento')
        setSeverity('error')
      })
    }
    setOpen(false)
  }

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{event ? "Editar evento" : "Adicionar evento"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Preencha os campos abaixo para {event ? "editar" : "adicionar"} um novo evento.
          </DialogContentText>
          <TextField
            margin="dense"
            id="eventName"
            label="Nome"
            value={eventName}
            type="text"
            fullWidth
            onChange={(event) => setEventName(event.target.value)}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdmin && !isOperator}
          />
          <TextField
            margin="dense"
            id="description"
            label="Descrição"
            value={description}
            type="text"
            fullWidth
            onChange={(event) => setDescription(event.target.value)}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdmin && !isOperator}
          />
          <TextField
            margin="dense"
            id="startDate"
            helperText="Data de ínicio do evento"
            type="date"
            value={new Date(startDate).toISOString().split('T')[0]}
            fullWidth
            onChange={(event) => {
              setStartDate(event.target.value)
            }}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdmin && !isOperator}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={hasReward}
                onChange={(event) => setHasReward(event.target.checked)}
                name="hasReward"
                color="primary"
                disabled={!isAdmin && !isOperator}
              />
            }
            label="Tem premiação?"
          />
          <TextField
            margin="dense"
            id="duration"
            label="Duração em dias"
            value={duration}
            type="number"
            fullWidth
            onChange={(event) => setDuration(event.target.value)}
            InputLabelProps={{ shrink: true }}
            disabled={!isAdmin && !isOperator}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="owner-select-label">Proprietário do Evento</InputLabel>
            <Select
              labelId="owner-select-label"
              id="owner-select"
              value={selectedOwner}
              label="Proprietário do Evento"
              onChange={(event) => setSelectedOwner(event.target.value)}
              disabled={loading || (!isAdmin && !isOperator)}
            >
              {users.map((user: any) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name || user.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        {(isAdmin || isOperator) && (
          <DefaultButton onClick={handleSubmit}>
            {event ? "Editar" : "Adicionar"}
          </DefaultButton>
        )}
      </Dialog>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        onClick={() => setOpenSnackBar(false)}
      >
        <Alert onClose={() => setOpenSnackBar(false)} severity={severity as any}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
