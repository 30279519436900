import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle, children }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '12px',
        padding: '24px',
        marginBottom: '32px',
        border: '1px solid rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography
            variant="h4"
            component="h1"
            sx={{
              color: '#333',
              fontWeight: 600,
              marginBottom: subtitle ? '8px' : 0,
            }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="subtitle1"
              sx={{
                color: '#666',
                fontWeight: 400,
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
        {children && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {children}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default PageHeader; 