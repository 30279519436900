import axios from 'axios';
import { redirect } from 'react-router-dom';
import { baseURL } from '../App';

class AuthService {

  async getHeaderAuthorization() {
    return {
      headers: {
        'authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    }
  }

  async login(username: string, password: string) {
    console.log("LOGIN: ", username)
    const response = await axios.post(`${baseURL}/auth/login`, { username, password });
    await localStorage.setItem('accessToken', response.data.accessToken);
    await localStorage.setItem('isAuthenticated', 'true');
    if (response.status === 200) {
      redirect(response.data.redirectUrl);
    } else {
      console.log("ERRO:" + response.data.message);
      redirect('/login');
    }
    return response;
  }

  async register(name: string, username: string, password: string, email: string, role: string) {
    return axios.post(`${baseURL}/auth/register`, { name, username, password, email, role }, await this.getHeaderAuthorization());
  }

  async logout() {
    // 

    return axios.get(`${baseURL}/auth/logout`);
  }

  async verifyEmail(token: string) {
    return axios.get(`${baseURL}/auth/verify-email/${token}`);
  }

  async resetPassword(email: string) {
    return axios.post(`${baseURL}/auth/password-reset`, { email });
  }

  async updatePassword(token: string, password: string) {
    return axios.post(`${baseURL}/auth/reset/${token}`, { password });
  }

  async getUsers() {
    return axios.get(`${baseURL}/auth/users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  }

  async updateUser(userId: string, userData: any) {
    return axios.put(`${baseURL}/auth/users/${userId}`, userData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  }

  async deleteUser(userId: string) {
    return axios.delete(`${baseURL}/auth/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });
  }
}

export default new AuthService();