import { DialogContent, DialogContentText, TextField, DialogActions, Snackbar, Autocomplete, Dialog, DialogTitle } from "@mui/material";
import DefaultButton from "../inputs/buttons/DefaultButton";
import { useState, useEffect } from "react";
import AuthService from "../../services/AuthService";

interface FormUsersProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  user?: {
    id: string;
    name: string;
    username: string;
    email: string;
    role: string;
  } | null;
  onSuccess?: () => void;
}

export default function FormUsers({ open, setOpen, user, onSuccess }: FormUsersProps) {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    if (user) {
      setName(user.name);
      setUsername(user.username);
      setEmail(user.email);
      setRole(user.role);
      setPassword('');
      setConfirmPassword('');
    } else {
      limparCampos();
    }
  }, [user]);

  function handleSubmit(event: { preventDefault: () => void; }) {
    event.preventDefault();
    if (!user && password !== confirmPassword) {
      setOpenSnackBar(true);
      setMessage('As senhas não coincidem.');
      setSeverity('error');
      return;
    }
    if (!name || !username || (!user && !password) || !email || !role) {
      setOpenSnackBar(true);
      setMessage('Por favor, preencha todos os campos obrigatórios.');
      setSeverity('error');
      return;
    }

    const userData = {
      name,
      username,
      email,
      role,
      ...(password && { password })
    };

    if (user) {
      // Edit user
      AuthService.updateUser(user.id, userData)
        .then(() => {
          setOpenSnackBar(true);
          setMessage('Usuário atualizado com sucesso');
          setSeverity('success');
          setOpen(false);
          onSuccess?.();
        })
        .catch(() => {
          setOpenSnackBar(true);
          setMessage('Erro ao atualizar usuário');
          setSeverity('error');
        });
    } else {
      // Create user
      AuthService.register(name, username, password, email, role)
        .then(() => {
          setOpenSnackBar(true);
          setMessage('Usuário criado com sucesso');
          setSeverity('success');
          setOpen(false);
          onSuccess?.();
        })
        .catch(() => {
          setOpenSnackBar(true);
          setMessage('Erro ao criar usuário');
          setSeverity('error');
        });
    }
  }

  function limparCampos() {
    setName('');
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setEmail('');
    setRole('');
  }

  const handleUsernameChange = (event: any) => {
    const value = event.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (alphanumericRegex.test(value)) {
      setUsername(value);
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>{user ? 'Editar Usuário' : 'Adicionar Usuário'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {user ? 'Edite os campos abaixo para atualizar o usuário.' : 'Preencha os campos abaixo para adicionar um novo usuário.'}
        </DialogContentText>
        <TextField
          margin="dense"
          id="name"
          label="Nome"
          required={true}
          type="text"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          margin="dense"
          id="username"
          label="Nome de usuário"
          required={true}
          type="text"
          fullWidth
          value={username}
          onChange={handleUsernameChange}
          disabled={!!user}
        />
        {!user && (
          <>
            <TextField
              margin="dense"
              id="password"
              label="Senha"
              required={true}
              type="password"
              value={password}
              fullWidth
              onChange={(event) => setPassword(event.target.value)}
            />
            <TextField
              margin="dense"
              id="confirmPassword"
              label="Confirmar senha"
              required={true}
              value={confirmPassword}
              type="password"
              onChange={(event) => setConfirmPassword(event.target.value)}
              fullWidth
            />
          </>
        )}
        <TextField
          margin="dense"
          id="email"
          label="Email"
          required={true}
          value={email}
          type="email"
          fullWidth
          onChange={(event) => setEmail(event.target.value)}
        />
        <Autocomplete
          id="role"
          options={[{
            value: 'admin',
            label: 'Administrador'
          }, {
            value: 'user',
            label: 'Usuário'
          }, {
            value: 'operator',
            label: 'Operador'
          }]}
          getOptionLabel={(option) => option.label}
          fullWidth
          value={role ? { value: role, label: role === 'admin' ? 'Administrador' : role === 'operator' ? 'Operador' : 'Usuário' } : null}
          onChange={(event, value) => {
            if (value) {
              setRole(value.value);
            } else {
              setRole('');
            }
          }}
          renderInput={(params) => <TextField {...params} label="Cargo" />}
        />
      </DialogContent>
      <DialogActions>
        <DefaultButton onClick={() => setOpen(false)} color="primary">
          Cancelar
        </DefaultButton>
        <DefaultButton onClick={handleSubmit} color="primary">
          {user ? 'Atualizar' : 'Adicionar'}
        </DefaultButton>
      </DialogActions>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        onClick={() => setOpenSnackBar(false)}
        message={message}
        ContentProps={{
          sx: {
            backgroundColor: severity === 'success' ? '#4caf50' : '#f44336',
            color: 'white',
          }
        }}
      />
    </Dialog>
  );
}