/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TabelaSubscribers from '../components/tabelas/TabelaSubscribers';
import FormSubscribers from '../components/forms/FormSubscribers';
import DefaultButton from '../components/inputs/buttons/DefaultButton';
import ConsultaSubscriber from '../components/inputs/ConsultaSubscriber';
import DeletarSubs from '../components/inputs/DeletarSubs';
import ImportCSV from '../components/inputs/ImportCSV';
import { useEventoData } from '../hooks/useEventoData';
import { Box, Grid, Typography, Container } from '@mui/material';
import TicketsBox from '../components/dialogs/TicketsBox';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import SubscriberService from '../services/SubscriberService';
import PageHeader from '../components/PageHeader';

const Evento: React.FC = () => {
  const { id } = useParams<{ id: string }>() ?? { id: '' };
  const eventId = id ?? '';
  const { evento, participantes, setParticipantes, tickets, defaultSubscribers, buscarTickets, fetchData } = useEventoData(eventId);
  const [opcaoConsulta, setOpcaoConsulta] = useState('name');
  const [openSubscriberForm, setOpenSubscriberForm] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [confirmDeleteInput, setConfirmDeleteInput] = useState('');
  const [openTicketsDialog, setOpenTicketsDialog] = useState(false);
  const [dataGauge, setDataGauge] = useState({ inscritosCredenciados: 0, inscritosNaoCredenciados: 0 });
  const [totalPresenca, setTotalPresenca] = useState({ totalCredenciados: 0, totalInscritos: 0 });

  useEffect(() => {
    const fetchTotaisInscritos = async () => {
      const response = await SubscriberService.reportTotaisCredenciados(eventId);
      setDataGauge(response.data);
      const responsePresenca = await SubscriberService.reportTotaisPresenca(eventId);
      setTotalPresenca(responsePresenca.data);
    };

    fetchTotaisInscritos();
  }, [eventId]);

  return (
    <Container maxWidth="lg" style={{ padding: '20px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            title={evento?.eventName || 'Evento'}
            subtitle={`${new Date(evento?.startDate).toLocaleDateString('pt-BR')} - ${new Date(evento?.endDate).toLocaleDateString('pt-BR')}`}
          >
            <Box sx={{ display: 'flex', gap: 2 }}>
              <DefaultButton
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => setOpenSubscriberForm(true)}
              >
                Adicionar Participante
              </DefaultButton>
              <DefaultButton
                variant="contained"
                color="secondary"
                onClick={() => setOpenConfirmDelete(true)}
              >
                Deletar todos
              </DefaultButton>
              <DefaultButton onClick={() => setOpenTicketsDialog(true)}>
                Tickets
              </DefaultButton>
            </Box>
          </PageHeader>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mb: 4 }}>
            <Gauge
              width={200}
              height={200}
              value={totalPresenca.totalCredenciados}
              valueMax={totalPresenca.totalInscritos}
              startAngle={-110}
              endAngle={110}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 20,
                  transform: 'translate(0px, 0px)',
                },
              }}
              text={({ value, valueMax }) => `${value} / ${valueMax}`}
            />
            <Gauge
              width={200}
              height={200}
              value={dataGauge.inscritosCredenciados}
              valueMax={dataGauge.inscritosNaoCredenciados + dataGauge.inscritosCredenciados}
              startAngle={-110}
              endAngle={110}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 20,
                  transform: 'translate(0px, 0px)',
                },
              }}
              text={({ value, valueMax }) => `${value} / ${valueMax}`}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 4 }}>
            <ImportCSV eventId={eventId} buscarTudo={fetchData} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ width: "400px", margin: '0 auto' }}>
            <ConsultaSubscriber
              defaultSubscribers={defaultSubscribers}
              participantes={participantes}
              setParticipantes={setParticipantes}
              opcaoConsulta={opcaoConsulta}
              setOpcaoConsulta={setOpcaoConsulta}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" align="center" gutterBottom>
            Tabela de Participantes
          </Typography>
          <TabelaSubscribers
            subscribers={participantes}
            buscarTudo={buscarTickets}
            hasReward={evento?.hasReward}
            tickets={tickets}
          />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Typography variant="subtitle1">
              {participantes ? `Total: ${participantes.length}` : ""}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <FormSubscribers
        open={openSubscriberForm}
        setOpen={setOpenSubscriberForm}
        buscarTudo={buscarTickets}
        eventId={eventId}
        tickets={tickets}
      />

      <TicketsBox
        tickets={tickets}
        open={openTicketsDialog}
        setOpen={setOpenTicketsDialog}
        buscarTudo={buscarTickets}
        eventId={eventId}
      />

      <DeletarSubs
        eventId={eventId}
        buscarSubscribers={buscarTickets}
        openConfirmDelete={openConfirmDelete}
        setOpenConfirmDelete={setOpenConfirmDelete}
        confirmDeleteInput={confirmDeleteInput}
        setConfirmDeleteInput={setConfirmDeleteInput}
      />
    </Container>
  );
};

export default Evento;